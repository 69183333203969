/* Fonts */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Bootstrap Icons */

@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

/* Bootstrap */

$theme-colors: (
  "prop-green-light-2": #d0f1ea,
  "prop-red-light-2": #f1d3d3,
  "prop-blue": #459ed8,
  "prop-celestial-blue": #4098d7,
  "prop-dark-blue": #102a43,
  "prop-wild-blue": #9fb3c8,
  "prop-dark-eletric-blue": #486581,
  "prop-dark-jungle-green": #0c1c27,
  "prop-weldon-blue": #829ab1,
  "prop-anti-flash-white": #f0f4f8,
  "prop-azureish": #dceefb,
  "prop-black": #000000,
  "prop-cyan": #22a6ac,
  "prop-mint-cream": #f0fcf9,
  "prop-light-sea-green": #17b897,
  "prop-green": #00a64f,
  "prop-green-light": #e3f7ee,
  "prop-orange": #f89747,
  "prop-seashell-2": #fef5ed,
  "prop-vtex-dark": #ff3366,
  "prop-vtex-light": #f05179,
  "prop-rosa": #ffeeee,
  "prop-pink": #fd3366,
  "prop-success": #14a54d,
  "prop-blue-green": #17a2b8,
  "prop-powder-blue": #e8f6f8,
  "prop-danger": #bb2026,
  "prop-warning": #cb912b,
  "prop-warning-dark": #f89747,
  "prop-white": #ffffff,
  "prop-ghost-white": #f7f9fb,
  "prop-yellow-red": #e9b949,
  "prop-yellow": #fffdbd,
  "prop-warning-light": #ffcf1d,
  "prop-grey-light": #626679,
  "prop-slate-gray": #627d98,
  "prop-grey-medium": #4e4e4e,
  "prop-grey-dark": #3f4254,
  "prop-red": #ba2525,
  "prop-red-light": #ff2424,
  "prop-firebrick": #b02426,
  "prop-seashell": #ffeeee,
  "prop-firebrick-2": #ba2525,
);

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/* Font Size */

@for $i from 0 through 50 {
  .fz-#{$i} {
    font-size: calc(#{$i} * 0.063rem);
  }
}

@media only screen and (max-width: 1500px) {
  @for $i from 0 through 50 {
    .fz-#{$i} {
      font-size: calc((#{$i} * 0.063rem) - 10%);
    }
  }
  .scroll-sidebar {
    overflow: scroll;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 990px) {
  .donut-chart {
    height: auto;
  }
  .sprintTaskHeader {
    display: none;
  }
  .centerText {
    text-align: start !important;
  }
  .padding-schedule {
    padding-left: 0px !important;
  }
  .fc .fc-toolbar {
    .fc-toolbar-chunk:first-child {
      margin-bottom: 10px;
    }
    display: block;
  }
  .cardItemBody.heigthCard {
    height: 100%;
  }
}

@media only screen and (max-width: 790px) {
  .swal-modal {
    width: 320px !important;
  }
  .swal-button--danger {
    width: 130px !important;
  }
  .text-aling-center {
    text-align: start !important;
  }
  .cardItemBody.heigthCard {
    height: 100%;
  }
  .disaper {
    display: none !important;
  }
  .padding-hours {
    padding-left: 0px !important;
  }
  .page-container {
    padding-left: 0px !important;
  }
}
a:link {
  text-decoration: none;
}
a {
  color: #4098d7;
}

.select-agency {
  padding: 9px !important;
  width: 231px;
  font-size: 14px;
  font-weight: bold;
  color: #4098d7;
  border-color: #dceefb;
}

iframe {
  display: none;
}
.sprint-text {
  color: #626679;
}

.sprintTaskArrow.open {
  i {
    transform: rotate(180deg);
  }
}
.sprintTaskArrow.close {
  i {
    transform: rotate(0deg);
  }
}
.sprintCollapse.open {
  i {
    transform: rotate(180deg);
  }
}
.sprintCollapse.close {
  i {
    transform: rotate(0deg);
  }
}
.sprintCollapse-showTaskNoSprint.open {
  i {
    transform: rotate(180deg);
  }
}
.sprintCollapse-showTaskNoSprint.close {
  i {
    transform: rotate(0deg);
  }
}

.padding-schedule {
  padding-left: 20px;
}

.centerText {
  text-align: center;
}

.fc-scroller-harness .fc-scroller {
  width: 100% !important;
  overflow: hidden !important;
}

.text-aling-center {
  text-align: center;
}

.text-line-through {
  text-decoration: line-through;
}

.fw-medium {
  font-weight: 500;
}

.wrapper {
  perspective: 1500px;
}

/* Styles */
tbody tr th {
  font-weight: inherit;
}

.divider {
  border-bottom: 1px solid #e0e0e0;
}

.border-firebrick-2 {
  border: 1px solid #ba2525;
  border-radius: 4px;
}
.border-ligth-sea-green {
  border: 1px solid #17b897;
  border-radius: 4px;
}
.border-slate-gray {
  border: 1px solid #627d98;
  border-radius: 4px;
}
.border-blue-green {
  border: 1px #17a2b8 solid;
  border-radius: 4px;
}
.border-orange {
  border: 1px #f89747 solid;
  border-radius: 4px;
}

.border-bottom-celestial-blue {
  border-bottom: 2px solid #4098d7;
}

.border-bottom-transparent {
  border-bottom: 2px solid transparent;
}

.border-bottom-bright-gray {
  border-bottom: 2px solid #eaeff4;
}

.border-Y-none {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.border-top-task-item {
  border-top: 1px solid #bcccdc;
}

.swal-title:first-child {
  margin-top: 10px;
}

.swal-title {
  color: #3f4254;
  font-size: 35px;
}

.swal-text {
  color: #3f4254;
  padding-left: 17px;
  font-size: 25px;
}

.swal-modal {
  width: 566px;
}
.p-3.notification-scroll,
.MuiDrawer-paperAnchorRight,
.modal-dialog.modal-lg,
.modal-content,
.modal-body,
.modal-body .row.m-0 {
  max-height: 100% !important;
}

.swal-button--confirm {
  background-color: #22a6ac;
}

.swal-button--confirm:hover {
  background-color: #18858a !important;
}

.bg-prop-azureish:hover {
  background-color: #b6e0fe !important;
}

.swal-button--danger {
  background-color: #c03036;
  width: 195px;
}

.swal-button--danger:hover {
  background-color: #bb2026 !important;
}

.swal-button--cancel {
  color: #626679;
  background-color: inherit;
  text-transform: uppercase;
  border: 0px;
}
.avatar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 5px;
  border-color: #f2f2f2;
}

.avatar_user_selected {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.scroll::-webkit-scrollbar {
  width: 6px;
  scroll-margin: 2px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eff1f9;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #626679;
}

.red-button {
  background-color: #b02426;
  border: 1px solid #b02426;
  border-radius: 5px;
  color: #fff;
  padding: 5px 15px 5px 15px;
}

.red-button:hover {
  background-color: #911111;
  border: 1px solid #911111;
  border-radius: 5px;
  color: #fff;
}

.white-outline-button {
  background-color: inherit;
  border: 1px solid #4098d7;
  border-radius: 5px;
  color: #4098d7;
  padding: 5px 15px 5px 15px;
}

.white-outline-button:hover {
  background-color: #4098d7;
  border: 1px solid #4098d7;
  border-radius: 5px;
  color: #fff;
}

.save-close-button {
  background-color: inherit;
  border: 1px solid #4098d7;
  border-radius: 7px;
  color: #4098d7;
}

.save-close-button:hover {
  background-color: #4098d7;
  color: #eff1f9;
  border-radius: 7px;
  img.plusicon {
    filter: brightness(0) invert(1);
  }
}

.btn-prop-cyan:hover {
  color: #eff1f9;
  background-color: #18858a;
}

.btn-prop-pink:hover {
  color: #eff1f9;
  background-color: #e62f5d;
}

.btn-prop-blue:hover {
  color: #eff1f9;
  background-color: #367eac;
}

.btn-prop-light-sea-green {
  color: #eff1f9;
  background-color: #17b897;
}

.btn-prop-light-sea-green:hover {
  color: #eff1f9;
  background-color: #15a184;
}

.button-add:hover {
  color: #f9f9f9;
  background-color: #367eac;
}
.table-border-default {
  border: 1px solid #eaeff4;
  border-radius: 5px;
}

.delete-button {
  border: 1px solid #bb2026;
  background-color: inherit;
  color: #bb2026;
  border-radius: 10px;
}

.delete-button:hover {
  background-color: #bb2026;
  color: #ffffff;
  border-radius: 10px;
}

.button-add-new:hover {
  color: #f9f9f9;
  background-color: #367eac;
}

.button-add-layout:hover {
  color: #f9f9f9;
  background-color: #367eac;
}

.button-add-layout {
  color: #459ed8;
  background-color: white;
}

.save-close-button > i {
  color: #22a6ac !important;
}

.save-close-button:hover i {
  color: #f9f9f9 !important;
}

.padding-hours {
  padding-left: 28px;
}

/* Dashboard */

.font-number-widget {
  font-weight: 800 !important;
}

.dropdown-toggle-split[aria-expanded="true"] {
  i,
  img.icon-rotate,
  .material-icons {
    transform: rotate(180deg);
    transition: 300ms linear all;
    font-size: 16px;
  }
}
div.sprint-hover:hover {
  background: #d9e2ec;
  border-radius: 5px;

  input.sprint-title {
    background: #d9e2ec;
  }

  a.edit-icon {
    display: block;
    border-radius: 5px;
    background: #d9e2ec;
    cursor: pointer;
  }
}

a.edit-icon {
  display: none;
}

.dropdown-toggle-split[aria-expanded="false"] {
  i,
  img.icon-rotate,
  .material-icons {
    transform: rotate(0deg);
    transition: 300ms linear all;
    font-size: 16px;
  }
}

a.open.sprintCollapse .rotate-icon {
  transform: rotate(0deg);
  transition: 300ms linear all;
}

a.sprintCollapse .rotate-icon {
  transform: rotate(-180deg);
  transition: 300ms linear all;
}

.dropdown-menu {
  border-radius: 5px;
  box-shadow: 0px 6px 24px #00000030;
  li .dropdown-item:hover {
    background-color: #f0f4f8;
  }
}

.icon-button {
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}

.button-add-new {
  height: 45px;
}

.page-container {
  padding-left: 20px;
}

.avatar {
  border-radius: 2.25rem !important;
}

.fc-scrollgrid-sync-inner a {
  color: #d6d6d6;
  text-decoration: none;
}

.text-decoration-none {
  text-decoration: none;
}

// .fc-toolbar{
//     //display: none !important;
// }

.fc-daygrid-day-number {
  color: #3f4254 !important;
  font-weight: bold;
}

.fc .fc-day-future.fc-day-other {
  display: none;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f9f9f9;
  border: solid 2px #22a6ac !important;
}

.fc .fc-day-today .fc-daygrid-day-number {
  background-color: #22a6ac;
  color: white !important;
}

.fc .fc-h-event {
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 8px;
}

.card-with-image .border-special:last-child > * {
  border: 0px !important;
}

.card-with-image .border-special > * {
  border-bottom: 2px solid #eaeff4;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  font-size: 14px;
}

.react-calendar {
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.react-calendar__tile--hasActive {
  background: #4098d7;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  color: white;
  background: #4098d7;
}

.react-calendar__tile--active {
  background: #dceefb;
  color: #102a43;
}

.grey-date {
  background-color: #ececec !important;
  border-color: #ececec !important;
  border-radius: 8px;
  color: #4d4d4d;
}

.green-date {
  background-color: #22a6ac !important;
  border-color: #22a6ac !important;
  border-radius: 8px;
}

.red-date {
  background-color: #bb2026 !important;
  border-color: #bb2026 !important;
  border-radius: 8px;
  color: #fff;
}

.fc .fc-daygrid-day-events {
  height: 0px;
}

.fc .fc-daygrid-day-frame {
  overflow-x: auto;
  height: 100px;
}
/* width */
.fc .fc-daygrid-day-frame::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.fc .fc-daygrid-day-frame::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.fc .fc-daygrid-day-frame::-webkit-scrollbar-thumb {
  background: #0c9da4;
}

/* Handle on hover */
.fc .fc-daygrid-day-frame::-webkit-scrollbar-thumb:hover {
  background: #0c9da4;
}

.htmlTooltip {
  background-color: #f5f5f9;
  color: rgba(0, 0, 0, 0.87);
  max-width: 220;
  border: 1px solid #dadde9;
}

.widget-project-border-bottom:hover {
  background-color: #f9f9f9;
}

.notification-scroll {
  max-height: 225px;
  overflow: scroll;
  overflow-x: hidden;
}

/* Track */
.notification-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.notification-scroll::-webkit-scrollbar-thumb {
  background: #829ab1;
}

/* Handle on hover */
.notification-scroll::-webkit-scrollbar-thumb:hover {
  background: #829ab1;
}

.list-scroll {
  overflow: scroll;
  overflow-x: hidden;
}

/* Track */
.list-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.list-scroll::-webkit-scrollbar-thumb {
  background: #829ab1;
}

/* Handle on hover */
.list-scroll::-webkit-scrollbar-thumb:hover {
  background: #829ab1;
}

/* width */
.scroll-sidebar::-webkit-scrollbar,
.notification-scroll::-webkit-scrollbar,
.list-scroll::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scroll-sidebar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scroll-sidebar::-webkit-scrollbar-thumb {
  background: #486581;
}

/* Handle on hover */
.scroll-sidebar::-webkit-scrollbar-thumb:hover {
  background: #577694;
}

// Loading

.loading {
  width: 100%;
  height: 100%;
  display: none;
  list-style: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  background-image: url("../img/load_image.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100px;

  &:before,
  &:after,
  div {
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 4px solid $primary;
  }

  &:before {
    content: "";
    border: 15px solid #459ed8;
    border-color: transparent #459ed8 #459ed8 #459ed8;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: 170px;
    height: 170px;
    animation: scale 1s linear infinite;
  }

  div {
    border: 15px solid #459ed8;
    border-color: transparent #459ed8 #459ed8 #459ed8;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: 230px;
    height: 230px;
    transform: translate(-50%, -50%);
    animation: scale 3s linear infinite;
  }
}

.loading.open {
  display: block;
  position: absolute;
  top: 0;
}

@keyframes scale {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
/* Cliente Agency */
.new-page .card-header {
  border-bottom: none;
}

.filter-select {
  display: block;
  width: 100%;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
  font-family: $form-select-font-family;
  height: 37px;
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: #626679;
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: none;
  @include border-radius($form-select-border-radius, 0);
  @include box-shadow($form-select-box-shadow);
  border: 1px solid #eaeff4;
  appearance: none;
}

.table-body {
  border: 2px solid #eaeff4;
  border-radius: 5px;
}

.area-chart div svg {
  max-width: 100%;
}
.gant-chart .chart.card-body div svg {
  max-width: 100%;
}
.donutChart #pie svg {
  max-width: 100%;
}

.card-body-addsprint {
  border-style: dashed !important;
  border: 1px solid #bcccdc;
}

.sprintCard .row {
  --bs-gutter-x: 0;
}

.singleProject .row {
  margin-left: 0px;
}

.filter-select:hover {
  border: 1px solid #626679;
}

.input-search:hover {
  border: 1px solid #626679;
  border-radius: 0.25rem;
}

.input-search {
  border: 1px solid #eaeff4;
  border-radius: 0.25rem;
}

.button-add {
  height: 100%;
  display: flex;
  align-items: center;
}

.rounded-circle {
  border-radius: 50% !important;
}

.underline-hover:hover {
  text-decoration: underline;
}

.underline-hover {
  text-decoration: none;
}

.bg-anti-flash-white-hover:hover {
  background-color: #f0f4f8;
}

.bg-anti-flash-white-hover {
  background-color: inherit;
}

.card-with-hover:hover {
  background: #eaeff4 !important;
  cursor: pointer;
  .three-dots {
    visibility: visible;
  }
}

.card-with-hover {
  border: 0px solid transparent;
}

.three-dots {
  visibility: hidden;
}

.card-sprints {
  margin: 0 8px;
}

.card-sprints:hover {
  border: 2px solid #459ed8 !important;
  cursor: pointer;
}

.iconButton {
  border: none;
  background-color: inherit;
  cursor: pointer;
  width: auto;
}

.burndown-data {
  .highcharts-container {
    width: 100% !important;
  }
  .highcharts-root {
    max-width: 100%;
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bbbbbb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3fdc81;
}

input:focus + .slider {
  box-shadow: 0 0 1px #626679;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.font-rubik {
  font-family: "Rubik", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

html,
body,
#root {
  height: 100%;
  background: #ffffff !important;
}

.dropdown-toggle::after {
  display: none;
}

.c-pointer {
  cursor: pointer !important;
}

.t-decoration-none {
  text-decoration: none;
}

.box-shadow-none {
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
  }
}

.form-sidebar-header {
  .dropdown {
    width: 100%;

    .form-check-label {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      color: #626679;
    }
  }
}

.widget-project-border-bottom {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: solid 1px;
  border-bottom-color: #eff0f8;

  &:last-child {
    border-bottom: 0;
  }
}

.btn:hover {
  border: 1px solid transparent;
  text-decoration: none;
}

.btn:focus {
  border: 1px solid transparent;
}

.btn.show {
  border: 1px solid transparent;
  text-decoration: none;
}

.btn-prop-disabled {
  &:disabled {
    background: #eff1f9;
    border-color: #eff1f9;
    color: #626679 !important;
    cursor: not-allowed !important;
  }
}

.column-chart {
  .highcharts-stack-labels {
    tspan {
      stroke: transparent;
      fill: transparent;
    }
  }
}

.donut-chart {
  height: 100%;
  .dxc-labels-group {
    text {
      font-family: "Poppins", sans-serif !important;
    }
  }
  .dxc-legend {
    text {
      font-family: "Poppins", sans-serif !important;
    }
  }
  .dxc-hole-template {
    svg {
      text {
        tspan {
          &:first-child {
            font-size: 250% !important;
          }
          &:last-child {
            font-size: calc(100% - 25%) !important;
          }
        }
      }
    }
  }
}

.empty .message {
  color: #3f4254;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.gant-chart .highcharts-container {
  width: 100% !important;
}
.area-chart .highcharts-container {
  width: 100% !important;
}

.chart .highcharts-text-outline {
  display: none;
}

.recharts-text.recharts-label tspan:first-child {
  font: normal normal bold 52px/63px Montserrat;
}

.table-responsive {
  max-height: 500px;
  overflow-x: auto;
}

.table-responsive::-webkit-scrollbar {
  border-radius: 10px;
  height: 6px;
  width: 5px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: #626679;
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #626679;
}
.inputsearch {
  height: 31px;
  border: none;
}
.input-group-append {
  button {
    height: 31px;
    background: white;
    width: 0px;
    i {
      font-size: 21px;
      margin-left: -10px;
    }
  }
}

.fc-daygrid-day-bottom {
  margin-top: 5px !important;
}

.fc .fc-daygrid-more-link {
  border: solid 1px #22a6ac;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  color: #22a6ac;
  font-size: 14px;
  border-radius: 5px;
}

.fc .fc-button:not(:disabled) {
  background: #22a6ac;
  border: none;
  margin-right: 5px;
}
.fc-popover-body {
  .fc-h-event {
    margin-bottom: 5px;
  }
}
.fc-theme-standard .fc-popover-header {
  background: #22a6ac;
  color: white;
}
.fc .fc-toolbar-title {
  text-transform: uppercase;
}

:focus-visible {
  outline: none;
}
.fc .fc-view-harness {
  //   height: 790px !important;
  width: 100%;
  background: white;
  border-radius: 7px;
}
.fc .fc-scrollgrid-liquid {
  border: none;
}

.fc-col-header .fc-theme-standard td,
.fc-theme-standard th {
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: 1px solid #ddd;
}
.fc .fc-scrollgrid-section > td {
  border: transparent;
}

.form-check-input:checked {
  background-color: #14a54d;
  border-color: #14a54d;
}
.plh {
  padding-left: 0px !important;
}
.m {
  margin-left: -5px;
}

.select-busca-btn {
  // height: 42px;
  // margin: 0 auto;
  position: relative;
  width: 100%;
  .menu-button {
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    padding: 10px 18px 10px 18px;
    height: 38px;
    border-radius: 8px;
    border: solid 1px #d5dfdb;
    font-weight: 500;
    color: #666;
    transition: all 0.2s ease-in-out;
    background: url("../img/icons/v.svg"), #fff;
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 10px 6px;
    text-align: left;
    display: inline-block;
    position: relative;
    display: grid;
    align-items: center;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 90%;
      display: block;
      line-clamp: 1;
      max-height: 32px;
    }
  }

  input.menu-button {
    padding: 10px 41px 10px 18px;
  }
  .menu {
    font-size: 14px;
    z-index: 99;
    width: 100%;
    max-height: 200px;
    padding-bottom: 10px;
    padding-top: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #d5dfdb;
    border-radius: 6px;
    background-color: #fff;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
    ul {
      min-height: 40px;
      margin-bottom: 0;
    }
    button {
      border: none;
      background: none;
      width: 100%;
      letter-spacing: -0.2px;
      color: #3f544e;
      align-items: center;
      padding: 10px 17px;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      justify-content: center;
      text-align: left;
      &:hover {
        background-color: #eaf4f1;
      }
    }
  }
  .menu.ativo {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
}

.overflow-dots {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  display: block;
  overflow: hidden;
}

.overflow-dots-sprints {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 130px;
  display: block;
  overflow: hidden;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #17b897;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #e9b949;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #cb0000;
}

.modal-drawer {
  max-height: 100%;
  width: 737px;
}

.image-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.image-container img {
  position: absolute;
  min-width: 150px;
  min-height: 150px;
}

.task-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
